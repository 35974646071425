import classNames from "classnames"
import React from "react"

import { useBounceGlobalValues, useTranslation } from "@bounce/i18n"

import SafeLink from "../../Generic/SafeLink/SafeLink"

export type AppAggregateRatingProps = {
  text?: string
  className?: string
  link?: boolean
  cityId?: string
}

export const AppAggregateRating = ({ text, className, cityId, link }: AppAggregateRatingProps) => {
  const { t } = useTranslation()
  const bounceGlobals = useBounceGlobalValues()

  const defaultText = t("cmp.rating.nextV5", "{{numLocations}}+ locations reviewed to keep your luggage safe", {
    numLocations: bounceGlobals.numLocations,
  })

  const content = (
    <p className={classNames("paragraph2 mb-2 flex text-neutral-800", className)}>{text ?? defaultText}</p>
  )

  if (link) {
    return (
      <SafeLink
        href={{
          pathname: "/reviews",
          query: { cityId },
        }}>
        {content}
      </SafeLink>
    )
  }

  return content
}
