import { DEFAULT_LANGUAGE } from "@bounce/i18n"

import type { Region } from "../libs/bounce/bounceSdk.types"
import type { CityLink } from "../libs/bounce/cities"
import { getCityUrlDefaultPath, getCityUrlPath } from "../libs/router"

export const filterByCountry = (city: Pick<CityLink, "country">, country: string) => city.country === country
export const filterByRegion = (city: Pick<CityLink, "region">, region: Region) => city.region === region
export const sortByName = (a: Pick<CityLink, "translatedName">, b: Pick<CityLink, "translatedName">) =>
  a.translatedName.localeCompare(b.translatedName)
export const sortByPriority = (a: Pick<CityLink, "priority">, b: Pick<CityLink, "priority">) => b.priority - a.priority

export const cityToLink = (
  city: Pick<CityLink, "slug" | "translatedName" | "translated" | "translations">,
  language: string,
) => {
  if (!city.translated) {
    return {
      name: city.translatedName,
      href: getCityUrlDefaultPath(city.slug),
      locale: DEFAULT_LANGUAGE,
    }
  }

  return {
    name: city.translatedName,
    href: getCityUrlPath({ language, city }),
  }
}

export const cityToEventLink = (
  city: Pick<CityLink, "slug" | "translatedName" | "translated" | "translations">,
  language: string,
) => ({
  name: city.translatedName,
  href: `/events/${city.slug}`,
  locale: !city.translated ? DEFAULT_LANGUAGE : language,
})
