import { useBounceGlobalValues, useTranslation } from "@bounce/i18n"
import type { IconSliderProps } from "@bounce/web-components"

import calendar from "@bounce/assets/big-icons/calendar.png"
import shield from "@bounce/assets/big-icons/shield-check.png"
import store from "@bounce/assets/big-icons/store.png"
import world from "@bounce/assets/big-icons/world.png"

export const useBenefits = (): IconSliderProps["items"] => {
  const { t } = useTranslation()
  const bounceGlobals = useBounceGlobalValues()

  const benefits = [
    {
      src: store,
      title: t("cmp.whyUseBounceLuggageStorage.locations.title", "{numLocations}+ locations", {
        numLocations: bounceGlobals.numLocations,
      }),
      description: t(
        "cmp.whyUseBounceLuggageStorage.locations.description",
        "No matter where you go, it’s easy to find luggage storage near you.",
      ),
    },
    {
      src: world,
      title: t("cmp.whyUseBounceLuggageStorage.cities.title", "{numCities}+ cities", {
        numCities: bounceGlobals.numCities,
      }),
      description: t(
        "cmp.whyUseBounceLuggageStorage.cities.description",
        "Store your bags wherever in the world you find yourself.",
      ),
    },
    {
      src: shield,
      title: t("cmp.whyUseBounceLuggageStorage.protection.title", "{protectionAmount} protection", {
        protectionAmount: bounceGlobals.bounceShieldProtection,
      }),
      description: t(
        "cmp.whyUseBounceLuggageStorage.protection.description",
        "{protectionAmount} of coverage means peace of mind that your things are safe.",
        { protectionAmount: bounceGlobals.bounceShieldProtection },
      ),
    },
    {
      src: calendar,
      title: t("cmp.whyUseBounceLuggageStorage.support.title", "24/7 support"),
      description: t(
        "cmp.whyUseBounceLuggageStorage.support.descriptionV2",
        "Change or cancel your booking anytime before your drop-off time for a full refund.",
      ),
    },
  ]

  return benefits
}
