import { DateTime } from "@bounce/date"

export type Interpolations = {
  [key: string]: string
}

const defaultInterpolations: Interpolations = {
  CURRENT_YEAR: DateTime.now().year.toString(),
}

export const interpolateText = <T>(text: T, interpolations: Interpolations = {}): T => {
  const placeholders = {
    ...defaultInterpolations,
    ...interpolations,
  }

  if (Array.isArray(text)) {
    return text.map(t => interpolateText(t, placeholders)) as T
  }

  if (typeof text !== "string") {
    return text
  }

  let newText: string = text
  Object.keys(placeholders).forEach(k => {
    const value = placeholders[k]
    if (value) {
      newText = newText.replace(new RegExp(`\\{\\{?\\s*${k}\\s*\\}\\}?`, "gi"), value)
    }
  })
  return newText as T
}
