import type { ReactNode } from "react"
import React from "react"

import { useTranslation } from "@bounce/i18n"
import { PNImage } from "@bounce/web-components"

import heroImage from "@/assets/img/default_hero_home.png"

export type HeroContainerProps = {
  children: ReactNode
}

export const HeroContainer = ({ children }: HeroContainerProps) => {
  const { t } = useTranslation("marketing-ls-home")

  return (
    <header className="relative mx-auto flex w-full max-w-screen-2xl flex-col">
      <div className="relative mb-8 h-[288px] overflow-hidden sm:h-min md:hidden lg:absolute lg:bottom-0 lg:right-0 lg:mb-0 lg:block lg:h-full lg:w-[664px] xl:w-[800px]">
        <PNImage
          src={heroImage}
          width={943}
          height={727}
          priority
          alt={t(
            "screen.homeNextScreen.hero.imageAltV2",
            "A smiling young woman looks away while holding her phone in front of a landmark. She carries a backpack which will be kept safe at a Bounce location while she explores.",
          )}
          loading="eager"
          data-chromatic="ignore"
          className="absolute h-full min-w-fit translate-x-8 object-contain lg:translate-x-40"
        />
      </div>
      <div className="relative z-[1] mx-auto flex h-full w-full max-w-screen-xl flex-1 flex-col px-6 pb-12 md:items-start md:pt-8 xl:pb-40">
        <div className="md:w-2/3 lg:w-2/4 xl:mt-16 xl:w-1/2">
          <div className="flex h-full w-full max-w-full flex-col pr-0 md:pr-2.5">{children}</div>
        </div>
      </div>
    </header>
  )
}
