import classNames from "classnames"
import React, { useRef } from "react"
import type { SwiperRef } from "swiper/react"
import { Swiper, SwiperSlide } from "swiper/react"

import type { Nullable } from "@bounce/util"

import type { SlideProps } from "./Slide"
import { Slide } from "./Slide"
import { SliderNavigation } from "../Slider/SliderNavigation/SliderNavigation"

export type IconSliderProps = {
  title: Nullable<string>
  subtitle?: Nullable<string>
  items: SlideProps[]
  trackEvent?: () => void
  rewind?: boolean
  showNavigation?: boolean
  sectionRef?: React.Ref<HTMLElement>
  className?: string
  titleAs?: "h2" | "h3"
}

export const IconSlider = ({
  title,
  subtitle,
  items,
  trackEvent,
  rewind = true,
  showNavigation = true,
  sectionRef,
  className,
  titleAs: TitleAs = "h2",
}: IconSliderProps) => {
  const swiperRef = useRef<SwiperRef>(null)

  const next = () => {
    swiperRef.current?.swiper.slideNext()
    trackEvent?.()
  }

  const prev = () => {
    swiperRef.current?.swiper.slidePrev()
  }

  return (
    <section
      ref={sectionRef}
      className={classNames("relative mx-auto flex w-full max-w-screen-xl flex-col", className)}>
      <TitleAs className="title2 max-w-[600px] px-6 text-black">{title}</TitleAs>
      {!!subtitle && <p className="paragraph1 mt-2 max-w-4xl text-neutral-800 md:mt-2.5 xl:mt-3">{subtitle}</p>}
      <div className={classNames("mt-14 md:mt-16")}>
        <Swiper
          ref={swiperRef}
          slidesPerView="auto"
          watchSlidesProgress
          spaceBetween={18}
          slidesOffsetBefore={24}
          slidesOffsetAfter={24}
          rewind={rewind}>
          {items.map(({ src, title, description, action, imageClassName }) => (
            <SwiperSlide key={title} style={{ width: 200, height: "auto" }}>
              <Slide
                src={src}
                title={title}
                description={description}
                action={action}
                imageClassName={imageClassName}
                titleAs={TitleAs === "h3" ? "h4" : undefined}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {showNavigation && (
          <SliderNavigation onClickNext={next} onClickPrev={prev} className="mt-8 w-full justify-end px-6" />
        )}
      </div>
    </section>
  )
}
