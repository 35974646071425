import classNames from "classnames"
import React from "react"

import { Benefits } from "./Benefits"
import { PricingSelector } from "./PricingSelector"
import { useSectionViewed } from "../../../hooks/useSectionViewed"
import type { Country } from "../../../libs/bounce/bounceSdk.types"

export type PricingProps = {
  defaultCountry: string
  countries: Country[]
  className?: string
}

export const Pricing = ({ countries, defaultCountry, className }: PricingProps) => {
  const { sectionRef } = useSectionViewed({ name: "PricingLuggageStorage" })

  return (
    <section
      ref={sectionRef}
      className={classNames(
        "mb-20 grid w-full grid-rows-2 gap-0 md:mb-3 lg:mb-0 lg:grid-cols-2 lg:grid-rows-1",
        className,
      )}>
      <Benefits />
      <PricingSelector countries={countries} defaultCountry={defaultCountry} />
    </section>
  )
}
