import { useTranslation } from "@bounce/i18n"
import type { Nullable } from "@bounce/util"

import type { GenericInterpolations } from "../../../../hooks/useGenericInterpolations"
import { interpolateText } from "../../../../libs/prismic/helpers"
import type { FormattedHomepage } from "../../../../libs/prismic/types"

export type UseSEOOptions = {
  homePage: Nullable<FormattedHomepage>
  interpolations: GenericInterpolations
}

export const useSEO = ({ homePage, interpolations }: UseSEOOptions) => {
  const [t] = useTranslation("marketing-ls-home")
  const { NUM_LOCATIONS } = interpolations

  const metaTitle = homePage?.metaTitle
    ? interpolateText(homePage.metaTitle, interpolations)
    : t("screen.homeScreen.metaTitleV1", "Bounce: Luggage Storage Near Me - Left Luggage & Lockers")

  const metaDescription = homePage?.metaDescription
    ? interpolateText(homePage.metaDescription, interpolations)
    : t(
        "screen.homeScreen.metaDescriptionV5",
        "Luggage Storage Near Me 24/7 - Store your baggage in {{numLocations}}+ worldwide locations. Bounce is the BEST luggage storage app for finding and booking left luggage & lockers near you!",
        { numLocations: NUM_LOCATIONS },
      )

  return {
    metaTitle,
    metaDescription,
  }
}
