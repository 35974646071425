import { useMemo } from "react"

import { formatStripeAmount, useBounceGlobalValues, useLocale } from "@bounce/i18n"
import type { GeoIpData } from "@bounce/util"

import type { Country } from "../libs/bounce/bounceSdk.types"
import { defaultPricing } from "../utils/pricing"

type UseUserLocationOptions = {
  countries: Country[]
  geoIpCountry?: string
}

export type EnrichedGeoIp = Omit<Partial<GeoIpData>, "country"> & {
  country: string
  countryDetails: Country | undefined
  luggagePricePerDay: string
  lowestPricePerDay: string
  luggagePriceCents: number
  lowestPriceCents: number
}

/**
 * Returns an enriched version of the GeoIP data, including the user's country and luggage price.
 * @param options - The options for the hook.
 * @param options.countries - The list of countries and their luggage prices.
 * @param options.geoIpCountry - The country to use if available.
 * @returns The enriched GeoIP data.
 */
export const useEnrichedGeoIp = ({ countries, geoIpCountry }: UseUserLocationOptions): EnrichedGeoIp => {
  const locale = useLocale()
  const bounceGlobals = useBounceGlobalValues()
  const country = geoIpCountry || locale.countryCode

  const enrichedGeoIp = useMemo(() => {
    const { defaultLowestLuggagePrice, defaultLuggagePrice } = defaultPricing
    // Get the user country price if available, otherwise use the default price.
    const countryDetails = countries.find(c => c.country === country)
    const luggagePricePerDay = countryDetails
      ? formatStripeAmount(countryDetails.luggagePrice, countryDetails.currency, locale.language)
      : bounceGlobals.luggagePricePerDay

    const lowestPricePerDay = countryDetails
      ? formatStripeAmount(countryDetails.lowestPrice, countryDetails.currency, locale.language)
      : bounceGlobals.lowestPricePerDay

    return {
      country,
      countryDetails,
      luggagePricePerDay,
      lowestPricePerDay,
      luggagePriceCents: countryDetails?.luggagePrice || defaultLuggagePrice,
      lowestPriceCents: countryDetails?.lowestPrice || defaultLowestLuggagePrice,
    }
  }, [countries, locale.language, bounceGlobals.luggagePricePerDay, bounceGlobals.lowestPricePerDay, country])

  return enrichedGeoIp
}
