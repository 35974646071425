import { useCallback, useMemo } from "react"

import { useTranslation } from "@bounce/i18n"

import { Region } from "../../../../../libs/bounce/bounceSdk.types.gen"
import type { CityLink } from "../../../../../libs/bounce/cities"
import { filterByCountry, filterByRegion, sortByName, sortByPriority, cityToLink } from "../../../../../utils/city"

type Options = {
  cities: CityLink[]
}

export const useCities = ({ cities }: Options) => {
  const { i18n } = useTranslation()

  const mapCityToLink = useCallback((city: CityLink) => cityToLink(city, i18n.language), [i18n.language])

  const sections = useMemo(
    () => ({
      usa: cities
        .filter(city => filterByCountry(city, "US"))
        .sort(sortByPriority)
        .slice(0, 28)
        .sort(sortByName)
        .map(mapCityToLink),
      canada: cities
        .filter(city => filterByCountry(city, "CA"))
        .sort(sortByPriority)
        .slice(0, 4)
        .sort(sortByName)
        .map(mapCityToLink),
      mexico: cities
        .filter(city => filterByCountry(city, "MX"))
        .sort(sortByPriority)
        .slice(0, 4)
        .sort(sortByName)
        .map(mapCityToLink),
      europe: cities
        .filter(city => filterByRegion(city, Region.Europe))
        .sort(sortByPriority)
        .slice(0, 48)
        .sort(sortByName)
        .map(mapCityToLink),
      asia: cities
        .filter(city => filterByRegion(city, Region.Asia))
        .sort(sortByPriority)
        .slice(0, 12)
        .sort(sortByName)
        .map(mapCityToLink),
      southAmerica: cities
        .filter(city => filterByRegion(city, Region.SouthAmerica))
        .sort(sortByPriority)
        .slice(0, 2)
        .sort(sortByName)
        .map(mapCityToLink),
      middleEast: cities
        .filter(city => filterByRegion(city, Region.MiddleEast))
        .sort(sortByPriority)
        .slice(0, 4)
        .sort(sortByName)
        .map(mapCityToLink),
      oceania: cities
        .filter(city => filterByRegion(city, Region.Oceania))
        .sort(sortByPriority)
        .slice(0, 10)
        .sort(sortByName)
        .map(mapCityToLink),
    }),
    [cities, mapCityToLink],
  )

  return sections
}
