import classNames from "classnames"
import React from "react"

import { Analytics } from "@bounce/analytics"
import { useTranslation } from "@bounce/i18n"
import { theme } from "@bounce/theme"
import { IconSlider, PNImage, useMediaQuery } from "@bounce/web-components"

import { useBenefits } from "./useBenefits"
import { useSectionViewed } from "../../../hooks/useSectionViewed"
import { InteractionEvents } from "../../../libs/analytics/events"

export type WhyUseBounceProps = {
  title?: string
  className?: string
  titleAs?: "h2" | "h3"
}

export const WhyUseBounce = ({ title, className, titleAs = "h2" }: WhyUseBounceProps) => {
  const { t } = useTranslation()
  const benefits = useBenefits()
  const { sectionRef } = useSectionViewed({ name: "WhyUseBounceSlider" })
  const isSmall = useMediaQuery(`(max-width: ${theme.spacing("screen-lg")}px)`)

  return (
    <>
      <section
        className={classNames(
          "relative mx-auto w-full max-w-screen-xl",
          "hidden gap-7 md:grid md:grid-cols-2",
          "px-6 pt-12 pb-section md:pt-32",
        )}>
        <div>
          <h2 className="title2">
            {title || t("cmp.whyUseBounce.titleV5", "Trusted by millions of customers worldwide")}
          </h2>
        </div>

        <div className="grid gap-4 md:grid-cols-2 md:gap-8">
          {benefits.map(benefit => {
            return (
              <div key={benefit.title}>
                <PNImage className="mb-5" src={benefit.src} alt="" height={100} width={100} />
                <h3 className="title6 mb-3">{benefit.title}</h3>
                <p className="paragraph2 text-neutral-800">{benefit.description}</p>
              </div>
            )
          })}
        </div>
      </section>

      {isSmall && (
        <IconSlider
          items={benefits}
          title={title || t("cmp.whyUseBounceSlider.titleV5", "Trusted by millions of customers worldwide")}
          trackEvent={() => Analytics.track(InteractionEvents.NextWhyBounceValuePropPressed)}
          rewind={isSmall}
          showNavigation={isSmall}
          className={classNames("md:hidden", className)}
          sectionRef={sectionRef}
          titleAs={titleAs}
        />
      )}
    </>
  )
}
