import classNames from "classnames"
import React from "react"

import { useTranslation } from "@bounce/i18n"
import { LinkList } from "@bounce/web-components"

import { useCities } from "./useCities"
import type { CityLink } from "../../../../../libs/bounce/cities"

export type LinkType = {
  name: string
  href: string
  locale?: string
}

type CityListProps = {
  cities: CityLink[]
  className?: string
}

export const CityList = ({ cities, className }: CityListProps) => {
  const [t] = useTranslation("marketing-ls-home")
  const { usa, canada, mexico, europe, asia, southAmerica, middleEast, oceania } = useCities({ cities })

  const linkWithTitle = (country: LinkType[]) =>
    country.map(link => ({
      ...link,
      title: t("luggageStorageInCity", "Luggage Storage in {{location}}", { location: link.name }),
    }))

  return (
    <div className={classNames("flex w-full flex-col", className)}>
      <div className="grid w-full grid-cols-1 gap-y-12 md:grid-cols-[10fr_3fr] md:gap-x-6">
        {/* United States */}
        <LinkList label={t("cmp.topCitiesByRegion.unitedStates", "United States")} links={linkWithTitle(usa)} />
        {/* Canada */}
        <LinkList label={t("cmp.topCitiesByRegion.canada", "Canada")} links={linkWithTitle(canada)} />
      </div>
      {/* Europe */}
      <LinkList
        label={t("cmp.topCitiesByRegion.europe", "Europe")}
        className="mt-12 w-full"
        links={linkWithTitle(europe)}
      />
      <div className="mt-12 grid w-full grid-cols-1 gap-y-12 md:grid-cols-[auto_auto] md:gap-x-6">
        {/* Asia */}
        <LinkList label={t("cmp.topCitiesByRegion.asia", "Asia")} links={linkWithTitle(asia)} />
        {/* Australia & New Zealand */}
        <LinkList
          label={t("cmp.topCitiesByRegion.australiaNewZealand", "Australia & New Zealand")}
          links={linkWithTitle(oceania)}
        />
      </div>
      <div className="mt-12 grid w-full grid-cols-1 gap-y-12 md:grid-cols-[auto_auto_auto] md:gap-x-6">
        {/* Mexico */}
        <LinkList
          label={t("cmp.topCitiesByRegion.mexico", "Mexico")}
          links={linkWithTitle(mexico)}
          className="w-full min-w-[120px]"
        />
        {/* Latin America */}
        <LinkList
          label={t("cmp.topCitiesByRegion.latinAmerica", "Latin America")}
          links={linkWithTitle(southAmerica)}
          className="w-full min-w-[120px]"
        />
        {/* Middle east */}
        <LinkList
          label={t("cmp.topCitiesByRegion.middleEast", "Middle East")}
          links={linkWithTitle(middleEast)}
          className="w-full min-w-[120px]"
        />
      </div>
    </div>
  )
}
