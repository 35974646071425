import React from "react"

import { useFAQs } from "./useFAQs"
import type { FAQsProps as BaseFAQsProps } from "../../Generic/FAQs/FAQs"
import { FAQs as BaseFAQs } from "../../Generic/FAQs/FAQs"

export type FAQsProps = Pick<BaseFAQsProps, "className" | "title">

export const FAQs = ({ title, className }: FAQsProps) => {
  const faqs = useFAQs()

  return <BaseFAQs faqs={faqs} title={title} className={className} />
}
