import classNames from "classnames"
import React from "react"

import { Analytics, SharedInteractionEvents } from "@bounce/analytics"
import { useBounceGlobalValues, useTranslation } from "@bounce/i18n"
import type { PNImageProps } from "@bounce/web-components"
import { PNImage } from "@bounce/web-components"

import bagsIcon from "./assets/bags.svg?url"
import shieldIcon from "./assets/shield.svg?url"
import supportIcon from "./assets/support.svg?url"
import { useUrl } from "@/libs/router"
import { useAppURL } from "@/utils/appURL"

import ArrowRightIcon from "@bounce/assets/icons/ArrowRight.svg"

export type BenefitsProps = {
  className?: string
}

type ItemProps = {
  src: PNImageProps["src"]
  text: string
}

const Benefit = ({ src, text }: ItemProps) => (
  <li className="flex flex-row items-center">
    <PNImage src={src} width={36} height={36} className="h-6 w-6 object-contain md:h-7 md:w-7 xl:h-9 xl:w-9" />
    <span className="paragraph1 ml-2.5 xl:ml-4">{text}</span>
  </li>
)

export const Benefits = ({ className }: BenefitsProps) => {
  const { t } = useTranslation()
  const bounceGlobals = useBounceGlobalValues()

  const { getUrl } = useUrl()
  const buildAppURL = useAppURL()

  const appUrl = buildAppURL(getUrl({ path: "/s" }))

  const benefits = [
    {
      src: shieldIcon,
      text: t("cmp.pricingLuggageStorageNext.benefit1TextV3", "{{insurancePriceText}} protection", {
        insurancePriceText: bounceGlobals.bounceShieldProtection,
      }),
    },
    {
      src: bagsIcon,
      text: t("cmp.pricingLuggageStorageNext.benefit2TextV2", "Free cancellation"),
    },
    {
      src: supportIcon,
      text: t("cmp.pricingLuggageStorageNext.benefit3Text", "24/7 customer support"),
    },
  ]

  const onCTAPressed = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault()

    Analytics.track(SharedInteractionEvents.CTAPressed, {
      cta: "PricingLuggageStorage",
    })

    location.assign(ev.currentTarget.href)
  }

  return (
    <div
      className={classNames(
        "flex w-full flex-col bg-neutral-100 px-6 pb-10 pt-16 md:pb-12 xl:items-end xl:pb-16 xl:pt-20",
        className,
      )}>
      <div className="max-w-[590px]">
        <h2 className="title2">{t("cmp.pricingLuggageStorageNext.titleV2", "Affordable and safe luggage storage")}</h2>
        <ul className="mt-6 grid grid-rows-3 gap-3.5 md:mt-9">
          {benefits.map(benefit => (
            <Benefit {...benefit} key={benefit.text} />
          ))}
        </ul>
        <a
          rel="nofollow"
          href={appUrl}
          onClick={onCTAPressed}
          className="label1 mt-8 flex flex-row items-center text-primary-700 transition-all hover:text-primary-800 md:mt-9">
          <span>{t("cmp.pricingLuggageStorageNext.cta", "Find storage")}</span>
          <ArrowRightIcon className="ml-1 mt-0.5 h-5 w-5" />
        </a>
      </div>
    </div>
  )
}
