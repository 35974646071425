import classNames from "classnames"
import React from "react"

import { useTranslation } from "@bounce/i18n"

import { CountrySelector } from "./CountrySelector"
import { usePricingSelector } from "./usePricingSelector"
import type { Country } from "../../../libs/bounce/bounceSdk.types"

export type PricingSelectorProps = {
  defaultCountry: string
  countries: Country[]
  className?: string
}
import background from "./assets/outlined-icons.png"

export const PricingSelector = ({ countries, defaultCountry, className }: PricingSelectorProps) => {
  const { t } = useTranslation()
  const { selectedOption, options, onChange } = usePricingSelector({ countries, defaultCountry })

  return (
    <div
      style={{ backgroundImage: `url(${background.src})` }}
      className={classNames(
        "relative z-0 flex w-full flex-col bg-primary-700 bg-cover bg-center px-6 pb-10 pt-16 md:pb-12 xl:pb-16 xl:pl-24 xl:pt-20",
        className,
      )}>
      <div className="flex flex-col xl:mt-2">
        <CountrySelector options={options} selectedOption={selectedOption} onChange={onChange} />
        <div className="block flex-row items-baseline md:flex">
          <span className="ml-0.5 mr-2 mt-6 inline-block text-xl font-normal leading-none text-white md:text-2xl">
            {t("cmp.pricingLuggageStorageNext.from", "from")}
          </span>
          <span className="mt-3 line-clamp-1 text-[60px] font-semibold leading-none text-white md:mt-6 md:text-[90px]">
            {selectedOption?.formattedPrice}
          </span>
        </div>
        <span className="ml-0.5 mt-3 text-xl font-normal leading-none text-white md:text-2xl">
          {t("cmp.pricingLuggageStorageNext.bagDay", "bag / day")}
        </span>
      </div>
      {/* <div className="absolute inset-[-44px] -z-1">
        <Image src={background} alt="" className="h-full object-cover object-center lg:object-left" quality={100} />
      </div> */}
    </div>
  )
}
