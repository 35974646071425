import dynamic from "next/dynamic"

import type { AppBannerSecondaryProps } from "./AppBannerSecondary/AppBannerSecondary"

const DynamicAppBannerSecondary = dynamic<AppBannerSecondaryProps>(
  () => import("./AppBannerSecondary/AppBannerSecondary").then(mod => mod.AppBannerSecondary),
  {
    ssr: false,
  },
)

export default DynamicAppBannerSecondary
