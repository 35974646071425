import { useRouter } from "next/router"
import React from "react"

import { Analytics } from "@bounce/analytics"
import { Trans } from "@bounce/i18n"
import type { Nullable } from "@bounce/util"
import type { FAQsProps as BaseFAQsProps } from "@bounce/web-components"
import { FAQs as BaseFAQs } from "@bounce/web-components"

import { useSectionViewed } from "../../../hooks/useSectionViewed"
import { InteractionEvents } from "../../../libs/analytics/events"
import type { FAQ } from "../../../utils/boilerplate"
import { FAQStructuredData } from "../StructuredData/StructuredData"

export type FAQsProps = {
  faqs: Nullable<FAQ[]>
  title?: Nullable<string> | React.ReactNode
} & Omit<BaseFAQsProps, "title" | "faqs">

export const FAQs = ({ title, faqs, ...faqsProps }: FAQsProps) => {
  const router = useRouter()
  const { sectionRef } = useSectionViewed({ name: "Faqs" })

  const onClickItem: BaseFAQsProps["onClickItem"] = ({ id, position }) => {
    Analytics.track(InteractionEvents.FAQItemPressed, {
      question: id,
      position,
      pageName: router.route,
    })
  }

  if (!faqs?.length) return null

  return (
    <>
      <BaseFAQs
        id="faq"
        title={
          title || (
            <Trans
              i18nKey="cmp.fAQsGenericNext.titleV5"
              defaults="Frequently <br />asked questions"
              components={{
                br: <br />,
              }}
            />
          )
        }
        faqs={faqs}
        sectionRef={sectionRef}
        onClickItem={onClickItem}
        {...faqsProps}
      />
      <FAQStructuredData faqs={faqs} />
    </>
  )
}
