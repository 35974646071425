import { useMemo } from "react"

import { formatStripeAmount, useBounceGlobalValues, useLocale } from "@bounce/i18n"

import { useEnrichedGeoIp } from "./useEnrichedGeoIp"
import type { Country } from "../libs/bounce/bounceSdk.types"

type UseGenericInterpolationsOptions = {
  countries?: Country[]
  geoIpCountry?: string
}

export type GenericInterpolations = {
  PRICE: string
  LOWEST_PRICE: string
  INSURANCE_AMOUNT: string
  COUNTRY_CODE: string
  NUM_LOCATIONS: string
  NUM_CITIES: string
  NUM_BAGS_STORED: string
}
/**
 * Returns an object with generic interpolated values based on the user's location and bounce countries.
 */
export const useGenericInterpolations = (props: UseGenericInterpolationsOptions): GenericInterpolations => {
  const countries = props.countries || []
  const locale = useLocale()
  const { country, countryDetails, lowestPriceCents } = useEnrichedGeoIp({
    countries,
    geoIpCountry: props.geoIpCountry,
  })
  const bounceGlobals = useBounceGlobalValues()
  const lowestPricePerDay =
    countryDetails && lowestPriceCents
      ? formatStripeAmount(lowestPriceCents, countryDetails.currency, locale.language)
      : bounceGlobals.lowestPricePerDay

  const interpolations: GenericInterpolations = useMemo(() => {
    const { bounceShieldProtection, numLocations, numCities, numBagsStored } = bounceGlobals

    return {
      COUNTRY_CODE: country,
      PRICE: lowestPricePerDay,
      LOWEST_PRICE: lowestPricePerDay,
      INSURANCE_AMOUNT: bounceShieldProtection,
      NUM_LOCATIONS: numLocations,
      NUM_CITIES: numCities,
      NUM_BAGS_STORED: numBagsStored,
    }
  }, [bounceGlobals, country, lowestPricePerDay])

  return interpolations
}
