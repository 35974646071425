import classNames from "classnames"
import React from "react"

import type { Nullable } from "@bounce/util"

import type { PNImageProps } from "../PNImage/PNImage"
import { PNImage } from "../PNImage/PNImage"

export type SlideProps = {
  src: PNImageProps["src"]
  title?: Nullable<string>
  description?: Nullable<string> | React.ReactNode
  action?: Nullable<React.ReactNode>
  imageClassName?: string
  titleAs?: "h3" | "h4"
}

export const Slide = ({ title, src, description, action, imageClassName, titleAs: TitleAs = "h3" }: SlideProps) => (
  <div className="flex w-full flex-col">
    <PNImage
      alt={title}
      src={src}
      height={140}
      width={190}
      imgixParams={{ fit: "max", height: 140 }}
      loading="lazy"
      className={classNames("h-[116px] object-contain object-left", imageClassName)}
    />
    {!!title && <TitleAs className="title5 mt-3 text-black">{title}</TitleAs>}
    {!!description && <p className="paragraph2 mt-3 text-neutral-800 md:text-base">{description}</p>}
    {!!action && action}
  </div>
)
